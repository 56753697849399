<template>
    <div id="MemberOverview" class="overview-style">
        <section class="filter-section">
            <ElForm
                :label-position="store.getters.labelPosition"
                label-width="90px"
                :model="memberFilter"
            >
                <div class="left">
                    <ElFormItem label="検索">
                        <ElInput
                            v-model.lazy="memberFilter.keyword"
                            @change="
                                updateQuery([['keyword', memberFilter.keyword]])
                            "
                            @keypress.enter="
                                updateQuery([['keyword', memberFilter.keyword]])
                            "
                            @submit.prevent
                        ></ElInput>
                    </ElFormItem>
                    <ElFormItem label="フィルター" class="closer">
                        <ElCheckbox
                            v-model="memberFilter.baseCategory"
                            label="基本セミナー"
                            @change="
                                updateQuery([
                                    ['baseCategory', memberFilter.baseCategory],
                                ])
                            "
                        ></ElCheckbox>
                    </ElFormItem>
                    <ElFormItem
                        ><ElCheckboxGroup
                            v-model="memberFilter.theologyCategory"
                            @change="
                                updateQuery([
                                    [
                                        'theologyCategory',
                                        memberFilter.theologyCategory,
                                    ],
                                ])
                            "
                        >
                            <ElCheckbox
                                v-for="item in store.getters
                                    .getTheologySubcategory"
                                :key="item.subCategory"
                                :label="item.subCategory"
                            ></ElCheckbox></ElCheckboxGroup
                    ></ElFormItem>
                </div>
                <div class="right">
                    <ElFormItem label="ソート">
                        <ElSelect
                            v-model="memberFilter.orderStr"
                            @change="
                                updateQuery([
                                    ['orderStr', memberFilter.orderStr],
                                ])
                            "
                        >
                            <ElOption
                                v-for="item in sortOptions"
                                :key="item.label"
                                :label="item.label"
                                :value="item.value"
                            ></ElOption>
                        </ElSelect>
                    </ElFormItem>
                </div>
            </ElForm>
        </section>
        <section class="table-section">
            <ElTable
                :data="
                    memberList.slice(
                        (currentPage - 1) * pageSize,
                        currentPage * pageSize
                    )
                "
                style="width: 100%; font-size: 12px"
            >
                <ElTableColumn label="名前" fixed>
                    <template #default="scope">
                        <router-link
                            :to="{
                                name: 'MemberDetails',
                                params: { userId: scope.row.userId },
                            }"
                        >
                            {{ scope.row.name }}
                        </router-link>
                    </template>
                </ElTableColumn>
                <ElTableColumn
                    prop="furigana"
                    label="ふりがな"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    prop="createdAt"
                    label="登録日"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    prop="qualification"
                    label="資格"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    prop="role"
                    label="担当真使"
                    fixed
                ></ElTableColumn>
                <ElTableColumn
                    v-for="item in courseList"
                    :key="item"
                    :label="item"
                    width="80"
                >
                    <template #default="scope">
                        <span v-if="scope.row.courseRecords[item]"> V </span>
                    </template>
                </ElTableColumn>
            </ElTable>
        </section>
        <section class="pagination-section">
            <ElPagination
                v-model:currentPage="currentPage"
                v-model:page-size="pageSize"
                :page-sizes="[10, 15, 20, 30]"
                layout="total, sizes, prev, pager, next, jumper"
                :total="memberList.length"
            />
        </section>
    </div>
</template>
<script>
export default {
    name: 'MemberOverview',
}
</script>
<script setup>
import { reactive, ref, onMounted, computed } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getMemberOverview } from '@/service/api'
import { initQuery, updateQuery } from '@/utils/query'
const store = useStore()
const route = useRoute()

onBeforeRouteUpdate((to) => {
    initMemberList(to.query)
})
onMounted(() => {
    initQuery(memberFilter)
})
initMemberList(route.query)
const currentPage = ref(1)
const pageSize = ref(10)
const courseList = computed(() => {
    let arr = ['基本セミナー']
    for (let item of store.getters.getTheologySubcategory) {
        arr.push(item.subCategory)
    }
    return arr
})
const sortOptions = [
    { value: 'createdAt,desc', label: '登録日' },
    { value: 'createdAt,asc', label: '登録日昇順' },
    { value: 'qualification,asc', label: '資格 A-Z' },
    { value: 'qualification,desc', label: '資格 Z-A' },
    { value: 'role,asc', label: '担当真使 A-Z' },
    { value: 'role,desc', label: '担当真使 Z-A' },
]
const memberFilter = reactive({
    keyword: '',
    baseCategory: false,
    theologyCategory: [],
    orderStr: '',
})
const memberList = ref([])
async function initMemberList(query) {
    let res = await getMemberOverview(query)
    memberList.value = res.data
}
</script>
<style lang="scss" scoped></style>
